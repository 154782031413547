<template>
  <div class="shopdet">
    <tabBar style="height: 80px" :cardIndex="2"></tabBar>
    <div style="height: 85px">
      <div class="headers">
        <div
          v-for="(item, index) in list"
          :key="index"
          class="tips"
          :class="filg == index ? 'active_tip' : ''"
          @click="qies(index, item.ids)"
        >
          <span
            :style="
              technology.length == 0 && item.ids == 'cons_js'
                ? 'color:#cccccc'
                : clinicals.length == 0 && item.ids == 'cont_lc'
                ? 'color:#cccccc'
                : videos.length == 0 && item.ids == 'con_vide'
                ? 'color:#cccccc'
                : !usesteps.name && item.ids == 'cont_bz'
                ? 'color:#cccccc'
                : problem.length == 0 && item.ids == 'cont_wt'
                ? 'color:#cccccc'
                : ''
            "
            >{{ item.name }}</span
          >
        </div>
      </div>
    </div>
    <div class="shopxx" id="shop_xq">
      <div>
        <div class="shop_tex">
          <div style="font-size: 32px; margin-bottom: 16px">
            {{ goosdata.goodsName }}
          </div>
        </div>
        <div class="shop_left">
          <div style="margin-right: 90px">
            <img
              class="shop_img"
              :src="skuimg ? skuimg : lunbimgs"
              @click="chakandatu"
            />
            <div class="shop_imgs">
              <img
                v-for="(item, index) in lunbimg"
                :key="index"
                @mouseover="move(item)"
                @click="adsimgad(item)"
                :class="item.filg ? 'activ' : ''"
                :src="item.img"
              />
            </div>
          </div>
          <div class="shop_right">
            <div class="shop_ge" v-if="type != 1">
              <div v-for="(item, index) in skulist" :key="index">
                <div style="font-size: 20px">{{ item.name }}</div>
                <div class="shop_cs">
                  <div
                    class="shop_csa"
                    v-for="(ites, ind) in item.values"
                    :key="ind"
                    :class="ites.isfor ? 'shop_actv' : ''"
                    @click="shops(ites, item.values)"
                  >
                    {{ ites.value }}
                  </div>
                </div>
              </div>
            </div>
            <div class="shop_ge" v-else>
              <div v-for="(item, index) in shopskusa" :key="index">
                <div style="font-size: 20px">{{ item.name }}</div>
                <div class="shop_cs">
                  <div class="shop_csa shop_actv">
                    {{ item.values }}
                  </div>
                </div>
              </div>
            </div>
            <div class="shop_gug">
              <span style="font-size: 20px" v-if="type != 1">￥</span>
              <span style="font-size: 36px" v-if="type != 1">{{
                skudata.price
              }}</span>
              <span style="font-size: 36px" v-if="type == 1"
                >{{ integraldata.points }} 积分</span
              >
              <span>累计销量 {{ goosdata.buyCount }}</span>
            </div>
            <div class="shop_but" v-if="type != 1">
              <div @click="cart('CART')">加入购物车</div>
              <div @click="cart('BUY_NOW')">立即购买</div>
            </div>
            <div class="shop_buts" v-else>
              <div @click="cart('POINTS')">立即兑换</div>
            </div>
            <span class="shop_zc" @click="policy">退货政策</span>
            <div class="shop_dp">
              <div @click="claadssadf(1)">
                <img src="../../assets/img/xie/jindon.png" />
                <span>京东</span>
              </div>
              <div @click="claadssadf(2)">
                <img src="../../assets/img/xie/tianm.png" />
                <span>天猫</span>
              </div>
							<div @click="claadssadf(3)">
							  <img src="../../assets/img/xie/dianpu.jpg" />
							  <span>微店</span>
							</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="dilog">
      <el-dialog title="退货政策" :visible.sync="dialogVisibles" width="40%">
        <div class="dilog_rel">
          {{ goosdata.policy }}
        </div>
      </el-dialog>
    </div>
    <el-row class="evaluate" id="shop">
      <el-col :span="4"><div class="grid-content bg-purple"></div></el-col>
      <el-col :span="16">
        <div style="padding-left: 22px; box-sizing: border-box">
          <div style="font-size: 40px; text-align: center">商品评价</div>
          <div class="eval">
            <div class="evals" role="none">
              <div>最新评价</div>
            </div>
          </div>
          <div
            class="evalxx"
            v-for="(item, index) in shopevaluatelist"
            :key="index"
          >
            <div class="evaltou">
              <img :src="item.memberProfile" />
              <span>{{ item.memberName }}</span>
            </div>
            <div class="evalcent">
              <div class="evalcentimg" v-if="item.haveImage">
                <img
                  v-for="(ites, ind) in item.images"
                  :key="ind"
                  :src="ites"
                />
              </div>
              <div class="evalcont">
                <div>{{ item.content }}</div>
                <div style="font-size: 14px">
                  <span>{{ item.createTime }}</span>
                </div>
              </div>
              <div class="reply" v-if="item.reply">
                <div class="replys">
                  <div>
                    <span>管理员（回复）：</span>
                    <span>{{ item.reply }}</span>
                  </div>
                </div>
                <div style="font-size: 14px">
                  <span>{{ item.replyTime }}</span>
                </div>
              </div>
            </div>
          </div>
          <div style="display: flex; flex-flow: row; justify-content: flex-end">
            <div
              style="cursor: pointer"
              @click="seemore"
              v-if="shopevaluatelists.length > 2"
            >
              查看更多
            </div>
          </div>
          <div class="evalxx" v-if="shopevaluatelists.length == 0">
            暂无评价内容
          </div>
        </div>
      </el-col>
      <el-col :span="4"><div class="grid-content bg-purple"></div></el-col>
    </el-row>
    <div class="dilogs">
      <el-dialog title="更多评价" :visible.sync="dialogVisible" width="60%">
        <div class="dilog_rel" @scroll="scrollEvent">
          <div
            class="evalxx"
            v-for="(item, index) in shopevaluatelists"
            :key="index"
          >
            <div class="evaltou">
              <img :src="item.memberProfile" />
              <span>{{ item.memberName }}</span>
            </div>
            <div class="evalcent">
              <div class="evalcentimg" v-if="item.haveImage">
                <img
                  v-for="(ites, ind) in item.images"
                  :key="ind"
                  :src="ites"
                />
              </div>
              <div class="evalcont">
                <div>{{ item.content }}</div>
                <div style="font-size: 14px">
                  <span>{{ item.createTime }}</span>
                </div>
              </div>
              <div class="reply" v-if="item.reply">
                <div class="replys">
                  <div>
                    <span>管理员（回复）：</span>
                    <span>{{ item.reply }}</span>
                  </div>
                </div>
                <div style="font-size: 14px">
                  <span>{{ item.reply_time }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-dialog>
    </div>
    <div class="contents">
      <div v-if="goosdata.describeSrc">
        <img :src="goosdata.describeSrc[0]" />
      </div>
    </div>
    <el-row class="contes" id="cons_js" v-if="technology.length > 0">
      <el-col :span="4"><div class="grid-content bg-purple"></div></el-col>
      <el-col :span="16">
        <div class="techn">技术细节</div>
        <div class="techns">
          <div style="margin-right: 46px">
            <div class="item" v-for="(item, index) in technology" :key="index">
              <p>{{ item.name }}</p>
              <img
                style="width: 100%"
                v-for="(ims, index) in item.imglsits"
                :key="index"
                :src="ims.url"
                alt=""
              />
              <div>{{ item.detail }}</div>
            </div>
          </div>
          <div>
            <div class="item" v-for="(item, index) in technologys" :key="index">
              <p>{{ item.name }}</p>
              <span>{{ item.detail }}</span>
            </div>
          </div>
        </div>
      </el-col>
      <el-col :span="4"><div class="grid-content bg-purple"></div></el-col>
    </el-row>
    <el-row class="contes" id="cont_lc" v-if="clinicals.length > 0">
      <el-col :span="4"><div class="grid-content bg-purple"></div></el-col>
      <el-col :span="16">
        <div class="techn" style="margin-top: 50px">使用对比</div>
        <div class="contrast">
          <div
            class="contrasts"
            v-for="(item, index) in clinicals"
            :key="index"
          >
            <div style="margin-right: 5px">
              <img :src="item.imgListone.url" />
              <span>使用之前</span>
            </div>
            <div>
              <img :src="item.imgListtwo.url" />
              <span>使用之后</span>
            </div>
          </div>
        </div>
      </el-col>
      <el-col :span="4"><div class="grid-content bg-purple"></div></el-col>
    </el-row>
    <el-row class="contes" id="con_vide" v-if="videos.length > 0">
      <el-col :span="4"><div class="grid-content bg-purple"></div></el-col>
      <el-col :span="16">
        <div class="techn" style="margin-top: 50px">产品视频</div>
        <div class="videos">
          <div class="videosa" v-for="(item, index) in videos" :key="index">
            <video :src="item.videoList[0]" controls></video>
            <div class="videotext">
              <p>{{ item.name }}</p>
              <span>{{ item.info }}</span>
            </div>
          </div>
        </div>
      </el-col>
      <el-col :span="4"><div class="grid-content bg-purple"></div></el-col>
    </el-row>
    <el-row class="contes" id="cont_bz" v-if="usesteps.name">
      <el-col :span="4"><div class="grid-content bg-purple"></div></el-col>
      <el-col :span="16">
        <div class="techn" style="margin-top: 50px">程序</div>
        <div class="step">
          <div>{{ usesteps.name }}</div>
          <span>{{ usesteps.info }}</span>
        </div>
        <div class="steps">
          <div
            class="stepitem"
            v-for="(item, index) in usesteps.programList"
            :key="index"
          >
            <div class="stepitems">
              <img :src="item.image" />
            </div>
            <div>
              <p>第{{ index + 1 }}步</p>
              <span>{{ item.numberName }}</span>
            </div>
          </div>
        </div>
      </el-col>
      <el-col :span="4"><div class="grid-content bg-purple"></div></el-col>
    </el-row>
    <el-row class="contes" id="cont_wt" v-if="problem.length > 0">
      <el-col :span="4"><div class="grid-content bg-purple"></div></el-col>
      <el-col :span="16">
        <div class="techn" style="margin-top: 50px">常见问题</div>
        <div style="margin-top: 70px; margin-bottom: 50px">
          <div class="problem" v-for="(item, index) in problem" :key="index">
            <div>
              <div class="cont" @click="show(item)">
                <i class="el-icon-arrow-up" v-if="item.type"></i>
                <i class="el-icon-arrow-down" v-else></i>
                <span style="color: #535353; font-size: 24px">{{
                  item.issue
                }}</span>
              </div>
              <div class="content_con" v-show="item.type">
                <div>{{ item.answer }}</div>
              </div>
            </div>
          </div>
          <div class="problem">
            <div class="cont" @click="routesfaq">
              <i class="el-icon-arrow-right"></i>
              <span style="color: #535353; font-size: 24px">其他问题</span>
            </div>
          </div>
        </div>
      </el-col>
      <el-col :span="4"><div class="grid-content bg-purple"></div></el-col>
    </el-row>
    <footers></footers>
    <div class="cssens" v-if="moadls" @click="guanbis">
      <img :src="skuimg ? skuimg : lunbimgs" alt="" />
    </div>
  </div>
</template>

<script>
import tabBar from "@/components/tabBar.vue";
import triangle from "@/components/triangle.vue";
import footers from "@/components/footer.vue";
import {
  shopxx,
  shopsku,
  shopcart,
  shoppinj,
  integshopxx,
} from "@/request/xie";
export default {
  components: {
    tabBar,
    triangle,
    footers,
  },
  data() {
    return {
      moadls: false,
      imgads: "",
      list: [
        { name: "概述", ids: "shop_xq" },
        { name: "评论", ids: "shop" },
        { name: "技术细节", ids: "cons_js" },
        { name: "使用对比", ids: "cont_lc" },
        { name: "产品视频", ids: "con_vide" },
        { name: "程序", ids: "cont_bz" },
        { name: "问答", ids: "cont_wt" },
      ],
      filg: 0,
      filgs: 5,
      shopid: "",
      skuid: "",
      goosdata: {},
      skudata: {},
      lunbimg: [],
      skulist: [],
      skuimg: "",
      lunbimgs: "",
      technology: [],
      technologys: [],
      clinicals: [],
      videos: [],
      problem: [],
      usesteps: {},
      shopevaluate: {},
      shopevaluatelist: [],
      shopevaluatelists: [],
      cartdata: {
        num: 1,
        skuId: "",
        cartType: "",
      },
      parsma: {
        pageNumber: 1,
        pageSize: 4,
      },
      dialogVisible: false,
      dialogVisibles: false,
      type: "",
      integralid: "",
      integraldata: {},
      shopskus: [],
      shopskusa: [],
      tokens: "",
    };
  },
  mounted() {
    if (this.$route.query.type) {
      this.type = this.$route.query.type;
    }
    if (this.$route.query.id && this.type != 1) {
      this.shopid = this.$route.query.id;
    } else {
      this.shopid = this.$route.query.goodsid;
      this.integralid = this.$route.query.id;
    }
    this.tokens = localStorage.getItem("token");
    if (this.type == 1) {
      this.geterjitshop();
    }
    this.getshopxx();
    this.$nextTick(() => {
      window.addEventListener("scroll", this.btns);
    });
  },
  methods: {
    claadssadf(index){
      if(index == 1){
        window.open("https://mall.jd.com/index-11806642.html", "_blank");
      }
      if(index == 2){
        window.open("https://apsbst.tmall.com/shop/view_shop.htm?spm=a230r.1.14.4.9cde5bffxis6i1&user_number_id=2213194609995", "_blank");
      }
			if(index == 3){
			  window.open("https://k.weidian.com/=w=LgAtc", "_blank");
			}
    },
    chakandatu() {
      this.moadls = true;
    },
    guanbis() {
      this.moadls = false;
    },
    //获取积分商品详情
    async geterjitshop() {
      let res = await integshopxx(this.integralid);
      //console.log(res)
      this.integraldata = res.result;
      this.skuimg = this.integraldata.thumbnail;
      if (this.integraldata.goodsSku.simpleSpecs) {
        let sk = this.integraldata.goodsSku.simpleSpecs.replace(
          /(^\s*)|(\s*$)/g,
          ""
        );
        let skr = sk.split(" ");
        this.shopskus = skr;
      }
    },
    //获取商品详情
    async getshopxx() {
      let res = await shopxx(this.shopid);
      this.goosdata = res.result;
      let sk = res.result.goodsGalleryList;
      if (sk.length > 3) {
        sk.splice(0, 3);
      }
      sk.forEach((item, index) => {
        this.lunbimg.push({ filg: false, img: item });
      });
      this.lunbimgs = this.lunbimg[0].img;
      this.skuid = this.goosdata.skuList[0].id;
      this.getskuxx();
      this.getshoppj();
      if (this.goosdata.describeSrc) {
        this.goosdata.describeSrc = this.goosdata.describeSrc.split(",");
      }
      //技术细节数据处理
      if (this.goosdata.technicalDetails) {
        let tech = JSON.parse(this.goosdata.technicalDetails);
        tech.forEach((item, index) => {
          if (index % 2 == 0) {
            this.technology.push(item);
          } else {
            this.technologys.push(item);
          }
        });
      }
      //临床数据
      if (this.goosdata.clinical) {
        this.clinicals = JSON.parse(this.goosdata.clinical);
        this.clinicals.forEach((item, index) => {
          item.imgListone = item.imgListone[0];
          item.imgListtwo = item.imgListtwo[0];
        });
      }
      //产品视频数据
      if (this.goosdata.video) {
        this.videos = JSON.parse(this.goosdata.video);
      }
      //使用步骤数据
      if (this.goosdata.program) {
        this.usesteps = JSON.parse(this.goosdata.program);
        this.usesteps.programList.forEach((item, index) => {
          item.image = item.image[0].url;
        });
      }
      //问题数据
      if (this.goosdata.commonProblem) {
        this.problem = JSON.parse(this.goosdata.commonProblem);
        this.problem.forEach((item, index) => {
          item.type = false;
        });
      }
      console.log(this.technology, "-------------", this.technologys);
    },
    //获取商品评价
    async getshoppj() {
      let res = await shoppinj(this.shopid, this.parsma);
      this.shopevaluate = res.result;
      res.result.records.forEach((item, index) => {
        if (item.images) {
          let sk = item.images.split(",");
          item.images = sk;
        }
      });
      let sk = JSON.parse(JSON.stringify(res.result));
      this.shopevaluatelists.push(...res.result.records);
      if (sk.records.length > 2) {
        this.shopevaluatelist = sk.records.splice(0, 3);
      } else {
        this.shopevaluatelist = this.shopevaluatelists;
      }
      console.log(this.shopevaluatelists);
      //console.log(res)
    },
    //获取sku详情
    async getskuxx() {
      let res = await shopsku(this.shopid, this.skuid);
      //console.log(res)
      this.skudata = res.result.data;
      if (this.type != 1) {
        this.skuimg = this.skudata.thumbnail;
      }
      this.handlesku(res.result.specs);
    },

    //处理sku数据
    handlesku(list) {
      let arr = [{}];
      if (!Array.isArray(list)) {
        return false;
      }
      list.forEach((item, index) => {
        item.specValues.forEach((spec, specIndex) => {
          let name = spec.specName;
          let values = {
            value: spec.specValue,
            quantity: item.quantity,
            skuId: item.skuId,
          };
          if (name === "images") {
            return;
          }

          arr.forEach((arrItem, arrIndex) => {
            if (
              arrItem.name == name &&
              arrItem.values &&
              !arrItem.values.find((i) => {
                return i.value === values.value;
              })
            ) {
              arrItem.values.push(values);
            }

            let keys = arr.map((key) => {
              return key.name;
            });
            if (!keys.includes(name)) {
              arr.push({
                name: name,
                values: [values],
              });
            }
          });
        });
      });
      arr.shift();
      this.skulist = arr;
      if (this.type == 1) {
        this.shopskusa = [];
        this.skulist.forEach((item, index) => {
          this.shopskusa.push({
            name: item.name,
            values: this.shopskus[index],
          });
        });
      }
      //默认选择一个规格
      if (this.type != 1) {
        this.skulist.forEach((item, index) => {
          item.values.forEach((ites, ind) => {
            ites.isfor = false;
            if (ind == 0) {
              ites.isfor = true;
            }
          });
        });
      }
      //console.log(this.skulist)
    },
    //监听滚动条
    btns() {
      let scrollTop =document.body.scrollTop || document.documentElement.scrollTop;
      //console.log(scrollTop)
      let sk = document.getElementById("shop");
      let skr = sk.offsetTop - 180;
      if (scrollTop < skr) {
        this.filg = 0;
      } else {
        this.filg = 1;
      }
      let sks = document.getElementById("cons_js");
			if(sks){
				let skrs = sks.offsetTop - 180;
				if (scrollTop > skrs) {
				  this.filg = 2;
				}
			}
      let sks_a = document.getElementById("cont_lc");
			if(sks_a){
				let skrs_a = sks_a.offsetTop - 180;
				if (scrollTop > skrs_a) {
				  this.filg = 3;
				}
			}
      let sks_b = document.getElementById("con_vide");
			if(sks_b){
				let skrs_b = sks_b.offsetTop - 180;
				if (scrollTop > skrs_b) {
				  this.filg = 4;
				}
			}
      let sks_c = document.getElementById("cont_bz");
			if(sks_c){
				let skrs_c = sks_c.offsetTop - 180;
				if (scrollTop > skrs_c) {
				  this.filg = 5;
				}
			}
      let sks_d = document.getElementById("cont_wt");
			if(sks_d){
				let skrs_d = sks_d.offsetTop - 180;
				if (scrollTop > skrs_d) {
				  this.filg = 6;
				}
			}
    },
    //切换
    qies(ind, ids) {
      this.$nextTick(() => {
        let sk = document.getElementById(ids);
        //console.log(sk)
        if(sk){
					let skr = sk.offsetTop - 175;
					document.documentElement.scrollTop = skr;
				}
        //this.filg=ind
      });
    },
    adsimgad(item) {
      this.skuimg = item.img;
      this.lunbimg.forEach((item, index) => {
        item.filg = false;
      });
      this.$set(item, "filg", !item.filg);
    },
    //鼠标移入
    move(item) {
      this.skuimg = item.img;
       this.lunbimg.forEach((item, index) => {
        item.filg = false;
      });
      this.$set(item, "filg", !item.filg);
    },
    //鼠标移出
    remove() {
      if (this.type == 1) {
        this.skuimg = this.integraldata.thumbnail;
      } else {
        this.skuimg = this.skudata.thumbnail;
      }
      this.lunbimg.forEach((item, index) => {
        item.filg = false;
      });
      this.$forceUpdate();
    },
    //选择商品规格
    shops(item, list) {
      list.forEach((items, index) => {
        items.isfor = false;
      });
      this.$set(item, "isfor", !item.isfor);
      let sk = "";
      this.skulist.forEach((item, index) => {
        item.values.forEach((ites, ind) => {
          if (ites.isfor) {
            sk = String(sk + " " + ites.value);
          }
        });
      });
      this.goosdata.skuList.forEach((item, index) => {
        if (item.simpleSpecs.includes(sk)) {
          this.skudata = item;
        }
      });
      this.skuimg = this.skudata.thumbnail;
      this.$forceUpdate();
    },
    //立即购买、加入购物车
    cart(row) {
			let shietypes= localStorage.getItem("shietype");
			// if(shietypes==1){
			// 	this.$message.error('此功能正在建设中，敬请期待')
			// 	return false
			// }
      if (!this.tokens) {
        this.$message.error("请登录之后在操作");
        return false;
      }
      this.cartdata.skuId = this.skudata.id;
      this.cartdata.cartType = row;
      shopcart(this.cartdata).then((res) => {
        if (res.code == 200) {
          if (row == "CART") {
            this.$message.success("已成功加入购物车");
          } else {
            this.$router.push({
              path: "./closeORaccountpage",
              query: { way: row },
            });
          }
        }
      });
    },
    //显示隐藏内容
    show(item) {
      this.$set(item, "type", !item.type);
      this.$forceUpdate();
    },
    //查看更多评价
    seemore() {
      this.dialogVisible = true;
    },
    //退货政策
    policy() {
      this.dialogVisibles = true;
    },
    //加载更多评价
    scrollEvent(e) {
      if (
        e.srcElement.scrollTop + e.srcElement.clientHeight ==
        e.srcElement.scrollHeight
      ) {
        if (this.parsma.pageNumber <= this.shopevaluate.pages) {
          this.parsma.pageNumber = this.parsma.pageNumber + 1;
          this.getshoppj();
        }
      }
    },
    //跳转FAQ
    routesfaq() {
      this.$router.push({
        path: "/FAQ",
      });
    },
  },
  destroyed() {
    window.removeEventListener("scroll", this.btns);
  },
};
</script>

<style scoped lang="scss">
.grid-content {
  border-radius: 4px;
  min-height: 36px;
}
.shopdet {
  .headers {
    width: 100%;
    height: 85px;
    background-color: #ededed;
    color: #535353;
    font-size: 16px;
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 99;
    left: 0;
    top: 80px;
    .tips {
      width: 74px;
      height: 81px;
      text-align: center;
      line-height: 81px;
      margin-right: 100px;
      cursor: pointer;
    }
    .active_tip {
      color: #0075aa;
      border-bottom: 4px solid #0075aa;
    }
  }
  .shopxx {
    margin-top: 50px;
    width: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
    .shop_tex {
      width: 500px;
      color: #535353;
      font-size: 16px;
      margin-right: 90px;
    }
    .shop_left {
      color: #535353;
      font-size: 16px;
      display: flex;
      margin-top: 26px;
      .shop_img {
        width: 500px;
        height: 280px;
        border: 1px solid #d8d8d8;
      }
      .shop_imgs {
        width: 500px;
        display: flex;
        flex-flow: row;
        justify-content: space-between;
        align-items: center;
        margin-top: 38px;
        img {
          width: 160px;
          height: 90px;
          border: 1px solid #d8d8d8;
        }
        .activ {
          border: 4px solid #0075aa;
        }
      }
      .shop_imgs::after {
        content: "";
        width: 160px;
      }
    }
    .shop_right {
      color: #535353;

      .shop_cs {
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;
        margin-top: 17px;
        margin-bottom: 20px;
        .shop_csa {
          min-width: 80px;
          text-align: center;
          padding: 2px 6px;
          box-sizing: border-box;
          background-color: #f7f7f7;
          border: 2px solid #f7f7f7;
          margin-right: 10px;
          margin-bottom: 10px;
          color: #535353;
          font-size: 16px;
          cursor: pointer;
        }
        .shop_actv {
          border: 2px solid #0075aa;
        }
      }
      .shop_ge {
        width: 660px;
        border-bottom: 1px solid #bfbfbf;
      }
      .shop_gug {
        margin-top: 20px;
        span {
          color: #0075aa;
        }
        span:last-child {
          color: #959595;
          font-size: 14px;
          display: inline-block;
          margin-left: 10px;
        }
      }
      .shop_but {
        margin-top: 20px;
        display: flex;
        margin-bottom: 18px;
        div {
          padding: 15px 31px;
          box-sizing: border-box;
          font-size: 20px;
          border-radius: 3px;
          cursor: pointer;
        }
        div:nth-child(1) {
          border: 1px solid #8b8b8b;
          color: #535353;
          margin-right: 24px;
        }
        div:nth-child(2) {
          color: #fff;
          background-color: #0075aa;
        }
      }
      .shop_buts {
        margin-top: 20px;
        display: flex;
        margin-bottom: 18px;
        div {
          padding: 15px 31px;
          box-sizing: border-box;
          font-size: 20px;
          border-radius: 3px;
          cursor: pointer;
          color: #fff;
          background-color: #0075aa;
        }
      }
      .shop_zc {
        color: #535353;
        font-size: 16px;
        display: inline-block;
        padding-bottom: 1px;
        border-bottom: 1px solid #535353;
        cursor: pointer;
        margin-bottom: 28px;
      }
      .shop_dp {
        display: flex;
        div {
          cursor: pointer;
          margin-right: 16px;
          display: flex;
          flex-flow: column;
          justify-content: center;
          align-items: center;
          font-size: 14px;
          color: #535353;
          img {
            width: 45px;
            margin-bottom: 10px;
          }
        }
      }
    }
  }
  .evaluate {
    margin-top: 96px;
    color: #535353;
    .eval {
      width: 100%;
      border-bottom: 1px solid #0075aa;
      display: flex;
      flex-flow: row;
      justify-content: flex-end;
      overflow: hidden;
    }
    .evals {
      width: 130px;
      height: 30px;
      color: #0075aa;
      position: relative;
      div {
        position: absolute;
        padding-right: 19px;
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        display: flex;
        flex-flow: row;
        justify-content: flex-end;
        align-items: center;
        font-size: 16px;
        color: #fff;
      }
    }
    .evals::before {
      width: 100%;
      background: currentColor;
      transform: skew(-40deg);
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 12px;
    }
    .evals::after {
      width: 10px;
      background: #e8e8e8;
      transform: skew(-40deg);
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 10px;
    }
    .evalxx {
      padding: 17px 62px 24px 34px;
      box-sizing: border-box;
      width: 100%;
      background-color: #fff;
      box-shadow: 0px 7px 16px 0px rgba(168, 168, 168, 0.13);
      margin-bottom: 16px;
      .evaltou {
        display: flex;
        align-items: center;
        margin-bottom: 8px;
        img {
          width: 28px;
          height: 28px;
          border-radius: 50%;
          background-color: #000000;
          margin-right: 8px;
        }
        span {
          font-size: 18px;
          color: #535353;
        }
      }
      .evalcent {
        margin-left: 36px;
        .evalcentimg {
          margin-top: 6px;
          display: flex;
          margin-bottom: 18px;
          img {
            width: 96px;
            height: 96px;
            border: 1px solid #d8d8d8;
            margin-right: 15px;
          }
        }
        .evalcont {
          font-size: 16px;
          display: flex;
          flex-flow: row;
          justify-content: space-between;
          align-items: flex-end;
          div:first-child {
            width: 80%;
          }
        }
        .reply {
          display: flex;
          flex-flow: row;
          justify-content: space-between;
          align-items: flex-start;
          margin-top: 10px;
          padding: 10px 0;
          box-sizing: border-box;
          width: 100%;
          border-top: 1px solid #dfdfdf;
          .replys {
            display: flex;
            width: 85%;
            img {
              width: 28px;
              height: 28px;
              border-radius: 50%;
              margin-right: 13px;
            }
            div {
              width: 90%;
            }
          }
        }
      }
    }
  }
  .contents {
    margin-top: 100px;
    //padding: 0 30px;
    box-sizing: border-box;
    div {
      width: 100%;
      img {
        width: 100%;
        margin-bottom: 10px;
      }
    }
    margin-bottom: 30px;
  }
  .contes {
    background: linear-gradient(#fff, #f9f9f9);
  }
  .techn {
    text-align: center;
    font-size: 40px;
    color: #535353;
  }
  .techns {
    display: flex;
    margin-top: 80px;
    color: #535353;
    margin-bottom: 50px;
    .item {
      padding: 44px 55px 50px 48px;
      box-sizing: border-box;
      margin-bottom: 32px;
      width: 600px;
      background-color: #e2e2e2;
      border-radius: 2px;
      p {
        margin-bottom: 20px;
        font-size: 24px;
      }
      span {
        font-size: 16px;
      }
    }
  }
  .contrast {
    margin-bottom: 50px;
    margin-top: 80px;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;
    .contrasts {
      display: flex;
      // width: 625px;
      // height: 207px;
      padding: 10px;
      box-sizing: border-box;
      background-color: #fff;
      box-shadow: 0px 2px 29px 3px rgba(230, 230, 230, 0.67);
      border-radius: 2px;
      margin-bottom: 60px;
      div {
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        position: relative;
        img {
          width: 300px;
          height: 188px;
          display: block;
          margin-right: 2px;
        }
        span {
          color: #535353;
          font-size: 24px;
          text-align: center;
          position: absolute;
          width: 100%;
          top: 207px;
          left: 0;
        }
      }
    }
  }
  .videos:after {
    content: "";
    width: 400px;
  }
  .videos {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    margin-top: 80px;
    margin-bottom: 50px;
    .videosa {
      //margin-right: 25px;
      margin-bottom: 20px;
      width: 400px;
      background-color: #fff;
      border-radius: 2px;
      video {
        width: 400px;
        height: 278px;
      }
      .videotext {
        padding-top: 15px;
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 15px;
        p {
          font-size: 24px;
          margin-bottom: 5px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
        }
        span {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
        }
      }
    }
  }
  .step {
    margin-top: 50px;
    border-bottom: 1px solid #dfdfdf;
    padding-bottom: 23px;
    div {
      font-size: 32px;
    }
  }
  .steps:after {
    content: "";
    width: 390px;
  }
  .steps {
    margin-top: 43px;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    .stepitem {
      width: 390px;
      margin-bottom: 70px;
      .stepitems {
        width: 390px;
        height: 260px;
        background-color: #fff;
        padding: 4px;
        margin-bottom: 17px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      div {
        p {
          font-size: 24px;
          margin-bottom: 14px;
        }
      }
    }
  }
  .problem {
    width: 100%;
    background-color: #fff;
    border-radius: 2px;
    padding: 32px 41px 38px 41px;
    box-sizing: border-box;
    margin-bottom: 3px;
    .cont {
      cursor: pointer;
      i {
        color: #535353;
        font-size: 28px;
        margin-right: 30px;
      }
    }
    .content_con {
      margin-top: 21px;
      border-top: 1px solid #dfdfdf;
      padding: 25px 15px 0 60px;
      box-sizing: border-box;
      font-size: 16px;
      color: #535353;
    }
  }
  ::v-deep .dilogs {
    .el-dialog__header {
      text-align: center;
      padding: 0 0 0 20px;
      height: 50px;
      line-height: 50px;
      box-sizing: border-box;
      border-radius: 4px 4px 0px 0px;
      font-size: 16px;
      color: #535353;
      background-color: #f8f9fb;
      button {
        display: none;
      }
    }
    .el-dialog__body {
      padding: 22px;
      box-sizing: border-box;
    }
    .dilog_rel {
      height: 500px;
      overflow-y: auto;
      .evalxx {
        padding: 17px 42px 24px 34px;
        box-sizing: border-box;
        width: 100%;
        background-color: #fff;
        box-shadow: 0px 7px 16px 0px rgba(168, 168, 168, 0.13);
        margin-bottom: 16px;
        .evaltou {
          display: flex;
          align-items: center;
          margin-bottom: 8px;
          img {
            width: 28px;
            height: 28px;
            border-radius: 50%;
            background-color: #000000;
            margin-right: 8px;
          }
          span {
            font-size: 18px;
            color: #535353;
          }
        }
        .evalcent {
          margin-left: 36px;
          .evalcentimg {
            margin-top: 6px;
            display: flex;
            margin-bottom: 18px;
            img {
              width: 96px;
              height: 96px;
              border: 1px solid #d8d8d8;
              margin-right: 15px;
            }
          }
          .evalcont {
            font-size: 16px;
            display: flex;
            flex-flow: row;
            justify-content: space-between;
            align-items: flex-end;
            div:first-child {
              width: 80%;
            }
          }
          .reply {
            display: flex;
            flex-flow: row;
            justify-content: space-between;
            align-items: flex-start;
            margin-top: 10px;
            padding: 10px 0;
            box-sizing: border-box;
            width: 100%;
            border-top: 1px solid #dfdfdf;
            .replys {
              display: flex;
              width: 85%;
              img {
                width: 28px;
                height: 28px;
                border-radius: 50%;
                margin-right: 13px;
              }
              div {
                width: 90%;
              }
            }
          }
        }
      }
    }
  }
  ::v-deep .dilog {
    .el-dialog__header {
      text-align: center;
      padding: 0 0 0 20px;
      height: 50px;
      line-height: 50px;
      box-sizing: border-box;
      border-radius: 4px 4px 0px 0px;
      font-size: 16px;
      color: #535353;
      background-color: #f8f9fb;
      button {
        display: none;
      }
    }
    .el-dialog__body {
      padding: 22px;
      box-sizing: border-box;
    }
  }
}
.cssens {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  text-align: center;
  padding-top: 13%;
}
</style>
